import request from '../utils/request';

/**
 * 导入业绩目标
 * @param query
 * @returns {AxiosPromise}
 */
export const uploadPerformanceTarget = (file) => {
    return request({
        url: '/sys/coachPerformanceTarget/importCoachPerformanceTarget',
        headers: {
            "Content-type": "multipart/form-data",
        },
        method: 'post',
        data: file
    });
};

/**
 * 查询业绩目标列表
 * @param list
 * @returns {*}
 */
export const selectList = (list) => {
    return request({
        url: '/sys/coachPerformanceTarget/selectPageList',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(list),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};

/**
 * 查询业绩目标统计
 * @param list
 * @returns {*}
 */
export const statistics = (list) => {
    return request({
        url: '/sys/coachPerformanceTarget/statistics',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(list),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};

/**
 * 查询业绩目标排名
 * @param list
 * @returns {*}
 */
export const ranking = (list) => {
    return request({
        url: '/sys/coachPerformanceTarget/selectListSort',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(list),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};

/***
 * 导出
 * @param query
 * @returns {AxiosPromise}
 */
export function exportExcel(query) {
    return request({
        url: '/sys/coachPerformanceTarget/exportCoachPerformanceTarget',
        method: 'post',
        data: JSON.stringify(query),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    })
}

/**
 * 查询业绩目标饼状图
 * @param list
 * @returns {*}
 */
export const pieChart = (list) => {
    return request({
        url: '/sys/coachPerformanceTarget/statisticsByDeptId',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(list),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};

/**
 * 根据id更新业绩目标
 * @param list
 * @returns {*}
 */
export const updateTarget = (list) => {
    return request({
        url: '/sys/coachPerformanceTarget/updateById',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(list),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};