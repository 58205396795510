import request from '../utils/request';

/**
 * 机器人推送分摊
 */
export const pushApportion = () => {
    return request({
        url: '/sys/tools/pushApportion',
        method: 'get',
        dataType: "json",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};

/**
 * 机器人推送订单
 */
export const pushOrder = () => {
    return request({
        url: '/sys/tools/pushOrder',
        method: 'get',
        dataType: "json",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};

/**
 * 机器人推送课时
 */
export const pushCourse = () => {
    return request({
        url: '/sys/tools/pushCourse',
        method: 'get',
        dataType: "json",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};
/**
 * 机器人推送定金
 */
export const pushAdvance = () => {
    return request({
        url: '/sys/tools/pushAdvance',
        method: 'get',
        dataType: "json",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};


/**
 * 机器人推送器材
 */
export const pushEquip = () => {
    return request({
        url: '/sys/tools/pushEquip',
        method: 'get',
        dataType: "json",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};

/**
 * 机器人推送招商业绩
 */
export const pushZhaoShangCRMOrder = () => {
    return request({
        url: '/sys/tools/pushZhaoShangCRMOrder',
        method: 'get',
        dataType: "json",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};

/**
 * 调用批量挪课时
 */
export const postBatchMoveCourse = (query) => {
    return request({
        url: 'sys/web_batchMoveCourse/add',
        method: 'POST',
        data: JSON.stringify(query),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};


/**
 * 调用批量-转移校区中的 部分订单
 */
export const partOrderMoveArea = (file) => {
    return request({
        url: '/sys/orders/partOrderMoveArea',
        headers: {
            "Content-type": "multipart/form-data",
        },
        method: 'post',
        data: file
    });
};
